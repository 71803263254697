import React, { Component } from "react";
import axios from 'axios'
import lunr from 'lunr'
import AsyncSearchBox from './AsyncSearchBox'
import shim from 'string.prototype.matchall/shim'
shim()

class KanjiSearchBox extends Component {
  constructor(props) {
    super(props);
    this.primitiveUri = (props.uri !== undefined  && props.uri.length>0) ?
      props.uri : './rtk/primitives.rtk';
    this.state = {
      primitiveEntries: [],
      primitiveIdx: null,
    };
  }

  componentDidMount() {
    this.requestPrimitives();
  }

  requestPrimitives() {
    var self = this;
    axios({
      method: 'get',
      url: this.primitiveUri,
    })
    .then(function (response) {
      self.processPrimitives(response.data)
    })
    .catch(function (error) {
      console.log("Could not request RTK primitives")
    })  
  }

  processPrimitives(primitives) {
    var primIter = primitives
      .matchAll(/^([\d]*[1-9]+[\d]*):([\d]+):([^-:]):([^:]*):-?([^-:]*):-?(.*)$/gmu);
    var entries = new Map();
    const slashToSpace = (x) => (x.split("/").join(" "));
    for (const element of primIter) {
      var entry = {
        id: parseInt(element[1]),
        revNum: parseInt(element[2]),
        kanji: element[3],
        keyword: slashToSpace(element[4]),
        altkeyword: slashToSpace(element[5]),
        components: slashToSpace(element[6])
      };
      entries.set(""+parseInt(element[1]), entry);
    }

    var idx = lunr(function () {  
      this.field("keyword", {boost: 10});
      this.field("altkeyword");
      this.field("components");
      for (const [, value] of entries.entries()) {
        this.add(value);
      }
    });
    // set index & entries
    this.setState({
      primitiveEntries: entries, 
      primitiveIdx: idx
    });
  }

  searchPrimitives(query) {
    if (this.state.primitiveIdx && query.length > 2) {
      let queryTokens = query.replace(/[*+-]+/g, "").split(" ");
      queryTokens = queryTokens.filter(x => (x.length > 0));
      const joinSpace = (q) => (q.length > 1 ? q.join(" ") : q[0])
      const queryFull = joinSpace(queryTokens.map(q => "+" + q));
      const queryExtFull = queryFull + "*";
      var resultObjs = this.state.primitiveIdx.search(queryFull);
      var resultRefs = resultObjs.map((resultObj) => resultObj.ref);
      let possibleAdditionObjs = this.state.primitiveIdx.search(queryExtFull);
      let actualAdditionObjs = []
      for (const addition of possibleAdditionObjs) {
        const refIndex = resultRefs.indexOf(addition.ref);
        if (refIndex === -1) {
          actualAdditionObjs.push(addition);
        }
      }
      resultObjs = [].concat(resultObjs, actualAdditionObjs)
      resultObjs.sort(function(a, b){return b.score - a.score});
      var resultEntires = [];
      for (const resultObj of resultObjs) {
        const resultEntry = this.state.primitiveEntries.get(resultObj.ref);
        if (resultEntry) {
          resultEntires.push(resultEntry);
        }
      }
      return resultEntires;
    }
    else {
      return [];
    }
  }

  loadOptions(inputValue) {
    if (inputValue.length < 1) {
      return [];
    }
    let searchResults = this.searchPrimitives(inputValue);
    const joinSpace = (q) => (q.length > 1 ? q.join(" ") : q[0])
    const getKeywords = (a, b) => (
      joinSpace([].concat(a, b.split(" ")).filter(x=>x.length>0))
    );
    let options = searchResults.map((result)=> {
      const keywords = getKeywords(result.keyword, result.altkeyword);
      return ({ value: result.kanji, label: (result.kanji + ": " + keywords)});
    });
    const entries = [{value: inputValue, label: inputValue}, ...options];
    return entries;
  }

  promiseOptions(inputValue) {
    return new Promise(resolve => {
      resolve(this.loadOptions(inputValue));
    });
  }

  render() {
    const allProps = {...this.props, loadOptions: this.promiseOptions.bind(this)};
    return (<AsyncSearchBox
      {...allProps}
    />);
  }

}
 
export default KanjiSearchBox;