import React, { Component, createRef } from "react";
// import ReactDOM from "react-dom"
import ResultDiv from './ResultDiv'

class ResultGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: []
    };
    this.rootRef = createRef();
    this.init()
  }

  init() {
    this.entryRefs = [];
    this.visibleEntries = []
    this.previousVisibleEntries = []
    // array of unloaded images
    if (this.images && this.images.length > 0) {
        this.images.forEach((image)=>{
            image.onload = null;
            image.src = "";
        });
    }
    this.images = [];
    var self = this;
    const callback = entries => {
        self.previousVisibleEntries = self.visibleEntries;
        entries.forEach(entry => {
            let parentRef = self.entryRefs[entry.target.attributes.parentid.value];
            if (parentRef !== undefined) {
                self.visibleEntriesCallback(
                    parentRef.current,
                    entry.isIntersecting
                );
            }
        });
        self.setState({});
      };
    this.intersectObserver = new IntersectionObserver(callback, {
      root: this.rootRef.current,
      threshold: [0],
    });
  }

  clearEntries() {
    this.setState({entries: []});
    this.init();
  }

  visibleEntriesCallback(entry, visible) {
    var self = this;
    entry.setVisible(visible);
    let index = self.visibleEntries.indexOf(entry); 
    if (visible && index === -1) {
      self.visibleEntries.push(entry);
    } else if (!visible && index >= 0) {
      self.visibleEntries.splice(index, 1);
    }
  }

  addEntries(entries) {
    if (entries.length === 0) {
      return;
    }
    var self = this;
    entries.map(function(result) {
      var src = result.src;
      const image = new Image();
      image.onload = () => {
        result.width = image.width;
        result.height = image.height;
        self.addLoadedEntries(result);
      }
      image.src = src;
      self.images.push(image);
      return image;
    })
  }

  addEntryToVisibleObserver(ref) {
    if (ref.current == null || ref.current.getDivRef().current == null) {
      setTimeout(()=>this.addEntryToVisibleObserver(ref), 200);
      return;
    }
    this.intersectObserver.observe(ref.current.getDivRef().current);
  }

  addLoadedEntries(newEntry) {
    var ref = createRef();
    newEntry.ref = ref;
    newEntry.visible = true;
    this.entryRefs[newEntry.id] = ref;
    this.setState({
      entries: [...this.state.entries, newEntry],
    });
    setTimeout(()=>this.addEntryToVisibleObserver(ref),200);
  }

  render() {
    var self = this;
      return (
        <div className="resultsContainer">
            {self.state.entries.map((entry) => (
                <ResultDiv ref={entry.ref} 
                        key={entry.id} 
                        entry={entry} 
                        parentid={entry.id} 
                        visibleEntriesCallback={(entry, visible)=>self.visibleEntriesCallback(entry, visible)}
                />
            ))}
        </div>
      );
  }
}

export default ResultGrid;

  // ReactDOM.render(
  //   <div>
  //     <ResultGrid  />
  //   </div>,
  //   document.getElementById('main')
  // );
  