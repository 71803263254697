import React, { Component, createRef } from "react";
class ResultDiv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            firsttime: true,
        }
        this.divRef = createRef();
        this.fadeRef = createRef();
        this.isFadingIn = false;
    }

    componentDidMount(){
        //this.divRef.current.classList.add("fadeIn");
        this.setState({});
        let callback = this.props.visibleEntriesCallback;
        if (callback !== undefined) {
            callback(this, true);
        }
    }

    getDivRef() {
        return this.divRef;
    }

    setVisible(visible) {
        if (visible && !this.isFadingIn) {
            this.isFadingIn = true;
            let self = this;
            const image = new Image();
            image.onload = () => {
                self.makeVisible();
            };
            image.src = this.props.entry.src;
        } else { // visible = false
            this.isFadingIn = false;
            let className = "fadeIn";
            let classList = this.divRef.current.classList;
            if (classList !== undefined && classList.contains(className)) {
                classList.remove(className);
            }
            this.setState( {
                visible: false,
                firsttime: false,
            });
        }
    }

    makeVisible() {
        let className = "fadeIn"
        let classList = this.divRef.current.classList;
        if(classList !== undefined && !classList.contains(className)) {
            classList.add(className);
        } 
        this.isFadingIn = false;
        this.setState( {
            visible: true,
            firsttime: false,
        });
    }

    giveImg() {
        if (this.state.visible) {
            
            var source = this.props.entry.src;
            return (
                <img src={source} alt={""} style={{"maxWidth": "100%"}} />

            );
        } else {
            var width = this.props.entry.width; 
            var height = this.props.entry.height;
            var srcStr = ( "data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' width%3D'" 
                        + width + "' height%3D'"+ height + "' viewBox%3D'0 0 " + width + " " + height + "'%2F%3E");
            return (
                <img src={srcStr} alt={""} style={{"maxWidth": "100%"}} />
            );
        }
    }

    render() {
        var source = this.props.entry.src;
        var captionList = [];
        if (this.props.entry.author) {
            captionList.push(this.props.entry.author);
        }
        if (this.props.entry.title) {
            captionList.push(this.props.entry.title);
        }
        if (this.props.entry.origin) {
            captionList.push(this.props.entry.origin);
        }
        if (!this.state.visible){
            var width = this.props.entry.width; 
            var height = this.props.entry.height;
            source = ( "data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' width%3D'" 
                        + width + "' height%3D'"+ height + "' viewBox%3D'0 0 " + width + " " + height + "'%2F%3E");
        }
        return (
        <div ref={this.divRef} parentid={this.props.parentid} className={"resultDiv"}>
            <div ref={this.fadeRef} className="resultImage">
                <img src={source} alt={""} style={{"maxWidth": "100%"}} />
            </div>
            <div className="resultText">
                {captionList.map((caption) => (
                    <div key={caption}>
                    {   caption}
                    </div>
                ))}
            </div>
        </div>
        );
    }
}

export default ResultDiv;