
import React from "react";
import AsyncSelect from 'react-select/async';

class AsyncSearchBox extends AsyncSelect {

  constructor(props) {
    super(props);
    this.externalOnChangeCallback = (props.onChange !== undefined) ? value=>props.onChange(value) : undefined;
    this.externalSelectValueCallback = (props.onSelect !== undefined) ? value=>props.onSelect(value) : undefined;
    this.state = {
      enteredWord: {value: "", lable: ""},
      inputValue: "",
      menuIsOpen: false,
    };
    this.previousInput = "";
  }

  onChangeCallback(input) {
    if (this.externalOnChangeCallback !== undefined) {
      this.externalOnChangeCallback(input);
    }
    let nextInput = input;
    this.setState({enteredWord: nextInput});
  }

  onSelectCallback(value) {
    if (this.externalSelectValueCallback !== undefined) {
      this.externalSelectValueCallback(value);
    }
  }

  getValueCallback() {
    let value = this.state.enteredWord;
    if (this.props.value !== undefined) {
      value = this.props.value;
    }
    return value;
  }

  hasValue() {
    var newEnteredWord = this.getValueCallback();
    return (newEnteredWord.value && newEnteredWord.value.length > 0);
  }

  onInputChange(value, event) {
    let action = event.action;
    if (action === "menu-close") { 
      //event.target.select();
      return; 
    }
    // else if (action=="input-blur") {
    //   //return;
    // }
    this.previousInput = value;
    let newInputValue = (action !== "set-value") ?
      value : this.state.inputValue;
    this.setState({
      inputValue: newInputValue
    });
    if (action === "set-value") {
      this.onSelectCallback(newInputValue);
    }
    else if (action==="input-change" && this.hasValue()) {
      this.onChangeCallback({value: "", label: ""});
    }
  }

  onChange(option, {action}) {
    if (option.value!== "" && action === "select-option") {
      this.onChangeCallback({value: option.value, label: option.value});
      this.previousInput = option.value;
    }
  }

  onFocus(event) {
    let newInputValue = this.state.inputValue;
    let newEnteredWord = this.getValueCallback();
    if (this.hasValue()) {
        newInputValue = newEnteredWord.value;
        this.previousInput = newInputValue;
        newEnteredWord = {value: "", label: ""};
    }
    this.props.loadOptions(newInputValue);
    //this.onChangeCallback(newEnteredWord); // Needed?
    this.setState({
      inputValue: newInputValue,
      menuIsOpen: true
    });
  }

  onMenuClose() {
    if (!this.hasValue()) {
      this.onChangeCallback({value: this.previousInput, label: this.previousInput});
    }
  }

  onBlur() {
    var newEnteredWord = this.getValueCallback();
    if (!this.hasValue()) {
      newEnteredWord = {value: this.previousInput, label: this.previousInput};
    }
    this.onChangeCallback(newEnteredWord);
    this.setState({
      menuIsOpen: false
    });
  }

  render() {
    const self = this;
    return (
      <AsyncSelect
          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
          cacheOptions
          defaultOptions
          blurInputOnSelect={true}
          menuIsOpen={this.state.menuIsOpen}
          onInputChange={this.onInputChange.bind(this)}
          onChange={this.onChange.bind(this)}
          onFocus={this.onFocus.bind(this)}
          noOptionsMessage={() => null}
          value={self.getValueCallback()}
          inputValue={this.state.inputValue}
          onBlur={this.onBlur.bind(this)}
          //placeholder={""}
          loadOptions={this.props.loadOptions}
        />
    );
  }
}

export default AsyncSearchBox;