import React, { Component, createRef } from "react";
import axios from 'axios'
import queryString from 'querystring'
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
//import AsyncSelect from 'react-select/async';
//import $ from 'jquery'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap'
//import DropdownButton from 'react-bootstrap/DropdownMenu'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'

import ResultGrid from './ResultGrid'
import KanjiSearchBox from './KanjiSearchBox'

const EventSource = NativeEventSource || EventSourcePolyfill;
// OR: may also need to set as global property
global.EventSource =  NativeEventSource || EventSourcePolyfill;

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: "",
            selectedScript: {value: "", label: ""},
            isRunning: false,
            scripts: ([
              "Block",
              "Grass",
              "Running",
              "Clerical",
              "Seal",
              "SealCutting",
              "Bamboo",
              "Regular",
              "Vector",
              "Oracle",
              "SmallSeal",
              "Shuowen",
              "Common",
              "SmallBlock"
            ].map(x => ({value: x, label: x}))),
            selectedKanji: {value: "", label: ""}
        };
        this.resultsGrid = createRef();
        this.searchBoxRef = createRef();
        this.submitButtonRef = createRef();
        this.primitiveIdx = null
        this.primitiveEntries = [];
        this.scripts = [];
    }

    componentDidMount() {

        const qs = window.location.search.replace(/^\?/, '');
        const parsed = queryString.parse(qs);
        const word = (parsed.w !== undefined) ? parsed.w : "";
        const script = (parsed.s !== undefined) ? parsed.s : "Block";

        this.setState({
            selectedScript: {label: script, value: script},
            selectedKanji: {value : word, label: word}
        });

        if (word) {
            this.attemptSearch(word, script);
        }
    }

    addStatus(status) {
        if (status) {
            console.log(status);
            this.setState({status: status});
        }
    }

    addStatusList(statusList) {
        var greatest_id = -1;
        var status = null;
        statusList.forEach(state => {
            console.log(state.value);
            if (state.id > greatest_id) {
                greatest_id = state.id;
                status = state.value;
            }
        });
        if (status != null) {
            this.setState({status: status});
        }
    }

    attemptSearch(word, script) {
        if (!word || this.state.isRunning) {
            return;
        }
        word = word.charAt(0);
        const allS = this.state.scripts.map(x=>x.value);
        if (script === undefined || !allS.includes(script)) {
            script = {value: "Block", label: "Block"};
        }
        this.setState({
          selectedScript: {value : script, label: script},
          selectedKanji: {value : word, label: word}
        });

        // lets go!
        this.resultsGrid.current.clearEntries();
        this.searchRequest(word, script);
    }

    searchRequestAsync(word, script) {

        this.setState({isRunning: true});

        let self = this;

        this.addStatus("Making API call");
        var source = new EventSource(
            '/api/streaming/' + word + '/' + script
        );

        source.onopen = function () {
            self.addStatus("Connection Opened");
        };
        source.onerror = function () {
            self.addStatus("Connection Error");
            self.setState({isRunning: false});
        };
        source.addEventListener('close', event => {
            source.close();
            self.addStatus("Completed");
            self.setState({isRunning: false});
        });

        source.addEventListener('status', event => {
            let data = event.data;
            let states = JSON.parse(data);
            self.addStatusList(states);
        });

        source.addEventListener('results', event => {
            self.resultsGrid.current.addEntries(JSON.parse(event.data));
        });
    }

    focusSubmit() {
      this.submitButtonRef.current.focus();
    }

    searchRequest(word, script) {
        var self = this;
        this.setState({isRunning: true});
        this.addStatus("Fetching full response (could take a while)");
        axios({
            method: 'get',
            url: '/api/search',
            params: {
            "w": word,
            "s": script,
            }
        })
        .then(function (response) {
            self.addStatus("Completed");
            self.resultsGrid.current.addEntries(response.data);
            self.setState({isRunning: false});
        })
        .catch(function (error) {
            self.addStatus("Completed");
            self.setState({isRunning: false});
        })
    }

    onChangeScript(option) {
        this.setState({selectedScript: option});
        this.focusSubmit();
    }

    onSubmit(event) {
        let word = this.state.selectedKanji.value;//this.searchBoxRef.current.getValue();
        let script = this.state.selectedScript.value;
        this.attemptSearch(word, script);
    }
    
  render() {
    var self = this;
    return (
      <div>
        <Navbar bg="light" expand="lg" sticky="top">
          <Navbar.Brand>Caligraphy Searcher</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="d-flex ml-auto align-items-sm-end flex-column flex-sm-row">
                  <div className="mr-0 mr-sm-2 d-flex flex-column align-items-lg-center flex-lg-row">
                    <div className="d-inline-block mr-0 mr-sm-2">Character:</div>
                    <div className="kanjiBox">
                      <KanjiSearchBox
                        ref={this.searchBoxRef}
                        value={this.state.selectedKanji}
                        onChange={(value) => {
                          self.setState({ selectedKanji: value });
                        }}
                        onSelect={(value)=> {
                          self.focusSubmit();
                        }}
                      />
                      </div>
                  </div>
                  <div className="mr-0 mr-sm-2 d-flex flex-column align-items-lg-center flex-lg-row">
                    <div className="d-inline-block mr-0 mr-sm-2">Script:</div>
                    <div className="scriptBox">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="btn navbar-btn dropdown-variants-secondary"
                          variant="secondary"
                          style={{ width: "100%" }}
                        >
                          {this.state.selectedScript.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="dropdown-menu scrollable-menu"
                          role="menu"
                          style={{ width: "100%" }}
                        >
                          {this.state.scripts.map((script) => (
                            <Dropdown.Item
                              key={script.value}
                              onClick={() => self.onChangeScript(script)}
                            >
                              {script.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      </div>
                    </div>
                    <div className="my-2 my-sm-0 searchButton">
                        <Button
                          className="btn btn-outline-succes"
                          ref={this.submitButtonRef}
                          variant="primary"
                          onClick={this.onSubmit.bind(this)}
                          disabled={this.state.isRunning}
                          style={{ width: "100%" }}
                        >
                          {this.state.isRunning ? "Working" : "Search"}
                        </Button>
                    </div>
            </div>
            </Navbar.Collapse>
        </Navbar>
        <div className="ml-4 mr-4">
          <div className="statusDiv">{this.state.status}</div>
          <ResultGrid ref={this.resultsGrid} />
        </div>
      </div>
    );
  }
}

export default App;
